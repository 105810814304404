import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    color: '#f8b200',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
  boldItalic: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  contentBox: {
    paddingBottom: '30px',
  },
  textBox: {
    paddingBottom: '20px',
  },
  grid: {
    padding: '20px 0px',
  },
  fileBtn: {
    color: 'black',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    '&:hover': {
      filter: 'brightness(0.5)',
    },
  },
  submitBtn: {
    color: '#fff',
    background: '#f98d23',
    padding: '10px',
    fontSize: '16px',
    '&:hover': {
      background: '#f98d23',
      filter: 'brightness(0.9)',
    },
  },
  uploadBox: {
    margin: '10px 0px 20px 0px',
    padding: '10px',
    borderRadius: '10px',
    border: '0.5px solid gray',
  },
  fullWidth: {
    width: '100%',
  },
  justifyBody: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'justify',
    },
  },
}));
