import React from 'react';
import { Box, Container, Typography, Tab } from '@material-ui/core';
import { title, webTechnicals } from '../../utils/constants/coreTechnicalConstants';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useStyles } from './style';
import CoreTech from '../../components/CoreTechnicalCompetencies/CoreTech';

function CoreTechnical(props) {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth={false}>
      <Typography variant="h2" component="h1" className={classes.title}>
        {title}
      </Typography>
      <CoreTech />
    </Container>
  );
}

export default CoreTechnical;
