export const title = ['DO YOU:', 'WHY BELLA GARDEN?'];

export const listBody = [
  'Want the flexibility to work from anywhere?',
  'Want to earn more while working on exciting projects?',
  'Have the skills to meet any technology challenge?',
  'Know you’re the best of the best?',
  'Think you’re ready to prove it to the world?',
];

export const test = [
  {
    title: 'DO YOU',
    body: [
      'Want the flexibility to work from anywhere?',
      'Want to earn more while working on exciting projects?',
      'Have the skills to meet any technology challenge?',
      'Know you’re the best of the best?',
      'Think you’re ready to prove it to the world?',
    ],
  },
  {
    title: 'WHY BELLA GARDEN?',
    body: 'Bella Garden allows our contractors the flexibility to work remotely from anywhere. We find the clients and bring the most interesting/challenging problems to solve directly to you. You get the benefits of applying your expertise to projects that resonate with you, growing your professional network and advancing your career.',
  },
  {
    title: 'WHO WE’RE LOOKING FOR',
    body1:
      'Our network is filled with technologists of all types. We’re always looking to add amazing new contractors to our network:',
    list1: [
      'Sr. Full Stack .NET Developers with strong Angular 2+ experience',
      'Sr. DevOps / Cloud Engineers (AWS / Azure / GCP)',
      'Sr. Ruby on Rails Developers',
      'Sr. Golang Developers',
      'Sr. Python Developers',
      'Sr. Full Stack Java Developers',
      'Sr. Salesforce CPQ, APEX, and API Developers',
    ],
    list2: [
      'Sr. QA / Automation / Testing / SDET',
      'Sr. DBA’s (MySQL / PostgreSQL / SQL / Oracle)',
      'Sr. API Developers',
      'Sr. iOS and Android Developers',
      'Sr. PHP / Laravel Developers',
      'Data Engineers / Big Data / BI / Tableau / Kafka',
      'Miscellaneous Developers (Figma / Angular 2+ / React / Vue.js / Ionic / Scala)',
    ],
    bold: 'We require five years of industry experience and you must be a permanent resident of the United States or Canada to apply.',
    body2:
      'If you’re interested in joining Bella Garden as a sales, marketing, operations or administrative professional, please send your resume to jobs@surgeforward.com.',
  },
];

export const boldText = [
  "Then apply to be part of Bella Garden' network of top software engineers.",
];

export const videoText = ['Here’s what our contractors say about Bella Garden lifestyle'];

export const listSr1 = [
  'Sr. Full Stack .NET Developers with strong Angular 2+ experience',
  'Sr. DevOps / Cloud Engineers (AWS / Azure / GCP)',
  'Sr. Ruby on Rails Developers',
  'Sr. Golang Developers',
  'Sr. Python Developers',
  'Sr. Full Stack Java Developers',
  'Sr. Salesforce CPQ, APEX, and API Developers',
];

export const listSr2 = [
  'Sr. Automation / Testing / SDET',
  'Sr. DBA’s (MySQL / PostgreSQL / SQL / Oracle)',
  'Sr. API Developers',
  'Sr. iOS and Android Developers',
  'Sr. PHP / Laravel Developers',
  'Data Engineers / Big Data / BI / Tableau / Kafka',
  'Miscellaneous Developers (Figma / Angular 2+ / React / Vue.js / Ionic \n/ Scala)',
];
