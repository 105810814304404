import { List, styled, makeStyles } from '@material-ui/core';

export const LogoGenerateList = styled(List)({
  display: 'inline-block',
  margin: '15px',
});

export const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.down('sm')]: {
      height: '25px',
    },
  },
}));
