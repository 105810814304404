import React from 'react';
import { useStyles } from './style';
import { Box, Typography, ButtonGroup, Link, styled, Button } from '@material-ui/core';

const buttonList = ['© Bella Garden Inc.', 'Terms of Use', 'Privacy Policy'];

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  display: 'block',
}));

function FooterEnd() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <StyledButtonGroup>
        {buttonList.map((button, index) => (
          <Button
            key={index}
            disableTouchRipple={true}
            disableFocusRipple={true}
            className={classes.linkButton}
          >
            {button}
          </Button>
        ))}
      </StyledButtonGroup>
    </Box>
  );
}

export default FooterEnd;
