import React from 'react';
import { Box, Container } from '@material-ui/core';
import News from './News';

function index(props) {
  return (
    <Box>
      <News />
    </Box>
  );
}

export default index;
