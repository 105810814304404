import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme';

export const blogStyle = makeStyles((theme: ITheme) => ({
  containerBox: {
    marginTop: '200px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  topFilerBox: {
    transform: 'translate(0,-15%)',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      position: 'unset',
      left: 'unset',
      transform: 'unset',
      zIndex: 0,
    },
  },
  blogList1: {
    width: '100%',
    padding: '0px 0px 100px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0px 100px 0px',
    },
  },
  blogList2: {
    width: '100%',
    transform: 'translate(0%,-15%)',
    [theme.breakpoints.down('xs')]: {},
  },
  pagination: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: '320px',
    paddingBottom: '80px',
  },
  borderBottomFilter: {
    background: 'white',
  },
  bottomFilter: {
    width: '60%',
    margin: 'auto',
    padding: '80px 0px',
  },
  filterList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  tag: {
    display: 'inline-flex',
    padding: '10px',
    textDecoration: 'none',
    color: 'black',
    textTransform: 'none',
    '&:hover': {
      background: 'unset',
    },
  },
  tagLi: {
    fontSize: '14px',
    color: '#a0a9b0',
    margin: '5px',
    fontWeight: 'bold',
  },
  tagLink: {
    textDecoration: 'unset',
    color: 'unset',
    transition: '0.5s',
    '&:hover': {
      color: '#428bff',
    },
  },
}));
