import { Box, createStyles, makeStyles, Theme, Toolbar } from '@material-ui/core';
import Header from '../../components/Header/index';
import Contact from '../../features/Contact/index';
import Home from '../../features/Home/index';
import OurTeam from '../../features/our_team/index';
import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import heroimage from '../../assets/header_images/heroimage.jpg';
import Footer from '../Footer/index';
import Tittle from '../../components/Header/components/tittle';
import Profile from '../../features/Profile';
import Blog from '../../features/Blog';
import WhoWeAre from '../../features/who_we_are/Components/WhoWeAre';
import HowWeEngage from '../../features/HowWeEngage';
import Career from '../../features/Career';
import Capabilities from '../../features/Capabilities';
import Developers from '../../features/Developers';
import {
  webTechnicals,
  dataTechnicals,
  mobileTechnicals,
  desktopTechnicals,
  devopsTechnicals,
  qaTechnicals,
} from '../../utils/constants/coreTechnicalConstants';
import ScrollToTop from 'hook/ScrollToTop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: '100%',
      background: `linear-gradient( rgba(248,178,0, 0.1) 100%, rgba(0, 0, 0, 0.5)100%), url(${heroimage})`,
    },
    container: {
      width: '100%',
      margin: '0px auto',
      backgroundColor: '#F8F8FA',
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
  }),
);

const technicals = [
  webTechnicals,
  dataTechnicals,
  mobileTechnicals,
  desktopTechnicals,
  devopsTechnicals,
  qaTechnicals,
];

const HomePage = () => {
  const classes = useStyles();

  return (
    <div>
      <ScrollToTop />
      <Header />
      <Box className={classes.box}>
        <Toolbar variant="dense" />
        <Toolbar />
        <Tittle />
        <Box className={classes.container}>
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Redirect exact from="/profile" to="/ourteam" />
            <Redirect exact from="/blog" to="/blog/all" />
            <Route path="/home" render={() => <Home />} />
            <Route path="/blog/:id" render={() => <Blog />} />
            <Route path="/whoweare" render={() => <WhoWeAre />} />
            <Route path="/howweengage" render={() => <HowWeEngage />} />
            <Route path="/careers" render={() => <Career />} />
            <Route path="/capabilities" render={() => <Capabilities />} />

            {technicals.map((technicals, index) =>
              technicals.map((item) => (
                <Route
                  key={index}
                  path={`/developers/${item.name}`}
                  render={() => (
                    <Developers
                      img={item.img}
                      title1={item.title1}
                      title2={item.title2}
                      body={item.body}
                    />
                  )}
                />
              )),
            )}

            <Route
              path="/hire-a-developer"
              render={() => (
                <Contact
                  title="Connect with us"
                  body="Sometimes you need to fill a single hole on your development team. Other times, you need the whole team. BellaGarden offers you the flexibility to scale up or down to meet your demands. From a single developer to multiple agile teams; designers and analysts to PMs and QA engineers; senior to junior level. We have the engineering capacity and ability to help you meet your business objectives."
                />
              )}
            />
            <Route
              path="/contact"
              render={() => (
                <Contact
                  title="Let’s Hear From You"
                  body="Please fill out the form and someone will contact you shortly. You can also reach us at 307.212.8124.."
                />
              )}
            />
          </Switch>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default HomePage;
