import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { filterCarđStyle } from './style';

interface IProps {
  filter: any;
}
export default function FilterCard(props: IProps) {
  const classes = filterCarđStyle();
  return (
    <Container style={{ padding: '0px' }} maxWidth="xl">
      <Box className={classes.containerBox}>
        <Box className={classes.borderBox}>
          <Link className={classes.linkText} to={`/blog/${props.filter.tag}`}>
            <Box className={classes.imgBox}>
              <img className={classes.image} src={props.filter.image} alt="" />
            </Box>
            <Typography className={classes.typoName} variant="h4" component="h1">
              {props.filter.name}
            </Typography>
            <Typography className={classes.typoDetail} variant="h6" component="h1">
              {props.filter.detail}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Container>
  );
}
