import { styled, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  reactPlayer: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
  },
});
