import { Box, Button, ButtonGroup, Typography, styled } from '@material-ui/core';
import React from 'react';
import Surge from '../../../../assets/FooterLogo/Surge.png';
import { navbarTitle } from '../../../../utils/constants/appConstants';
import AICPA from '../../../../assets/FooterLogo/AICPA.png';
import Clutch from '../../../../assets/FooterLogo/Clutch.svg';
import Star from '../../../../assets/FooterLogo/download.svg';
import ListIcon from './ListIcon';
import { useStyles } from './style';
import { buttonGroupTitle, addressTitle } from '../../../../utils/constants/appConstants';
import { pages } from '../../../../components/Header/components/nav_bar';
import { useHistory, Link, NavLink } from 'react-router-dom';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  '& .MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
    borderRight: '1px solid #fff',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
      borderRight: '0px solid black',
    },
    '& .MuiButtonGroup-groupedHorizontal:not(:first-child)': {
      padding: '10px 0px',
      [theme.breakpoints.down('xs')]: {
        margin: '10px 0px',
        borderRight: '0px solid black',
      },
    },
  },
}));

function SurgeFooter() {
  const classes = useStyles();
  const history = useHistory();

  const onNavigationHandler = (path) => {
    history.push(path);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="h3" component="h3" className={classes.imgBox}>
          Bella Garden Inc.
        </Typography>
      </Box>
      <Box>
        {pages.map((page, index) => (
          <Button
            onClick={() => onNavigationHandler(page.navigate)}
            className={classes.buttonList}
            variant="text"
            disableTouchRipple={true}
            key={index}
          >
            <NavLink
              key={index}
              className={classes.linkText}
              to={page.navigate}
              activeStyle={{
                color: '#f8b200',
              }}
            >
              {page.name}
            </NavLink>
          </Button>
        ))}
      </Box>
      <Box>
        <StyledButtonGroup variant="text" className={classes.buttonGroup}>
          {buttonGroupTitle.map((item, index) => (
            <Button
              key={index}
              disableTouchRipple={true}
              disableFocusRipple={true}
              className={classes.btn}
            >
              <Typography variant="body1" component="div" className={classes.infoList}>
                {item}
              </Typography>
            </Button>
          ))}
        </StyledButtonGroup>
      </Box>
      <Box>
        <Typography variant="body1" component="div" className={classes.addressTitle}>
          {addressTitle}
        </Typography>
      </Box>
      <Box className={classes.logoBox}>
        <Box className={classes.logoItem}>
          <img src={AICPA} alt="" width={60}></img>
        </Box>
        <Box className={classes.logoItem}>
          <Typography variant="body1" component="div" className={classes.clutch}>
            REVIEWED ON
          </Typography>
          <img src={Clutch} alt="" width={82}></img>
        </Box>
        <Box className={classes.logoItem}>
          <img src={Star} alt="" height={16}></img>
          <img src={Star} alt="" height={16}></img>
          <img src={Star} alt="" height={16}></img>
          <img src={Star} alt="" height={16}></img>
          <img src={Star} alt="" height={16}></img>
          <Typography variant="body1" component="div" style={{ color: '#fff' }}>
            <Typography className={classes.reviews}>{'5 REVIEWS'}</Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SurgeFooter;
