export const sponsorLogoList = [
  {
    name: 'Black and Decker',
    src: require('../../assets/logos/blackanddecker_logo.png').default,
    url: '/#',
  },
  {
    name: 'Black and Decker',
    src: require('../../assets/logos/bmw-logo.png').default,
    url: '/#',
  },
  {
    name: 'Black and Decker',
    src: require('../../assets/logos/bgking-logo.png').default,
    url: '/#',
  },
  {
    name: 'Black and Decker',
    src: require('../../assets/logos/kaiser-logo.png').default,
    url: '/#',
  },
  {
    name: 'Booz Allen Hamilton',
    src: require('../../assets/logos/boozallenhamilton_logo.png').default,
    url: '/#',
  },
  {
    name: 'Discovery',
    src: require('../../assets/logos/discovery_logo.png').default,
    url: '/#',
  },
  {
    name: 'Citizens Bank',
    src: require('../../assets/logos/logo-citizensBank1.png').default,
    url: '/#',
  },
  {
    name: 'Emory',
    src: require('../../assets/logos/logo-emory1.png').default,
    url: '/#',
  },
  {
    name: 'Forbes',
    src: require('../../assets/logos/logo-forbes1.png').default,
    url: '/#',
  },
  {
    name: 'Franklin Covey',
    src: require('../../assets/logos/logo-forbes1.png').default,
    url: '/#',
  },
  {
    name: 'McKesson',
    src: require('../../assets/logos/logo-mckesson.png').default,
    url: '/#',
  },
  {
    name: 'Logitech',
    src: require('../../assets/logos/logo-logitech.png').default,
    url: '/#',
  },
  {
    name: 'NTT',
    src: require('../../assets/logos/logo-ntt.png').default,
    url: '/#',
  },
  {
    name: 'Princess',
    src: require('../../assets/logos/logo-princess.png').default,
    url: '/#',
  },
  {
    name: 'Purdue',
    src: require('../../assets/logos/logo-purdue.png').default,
    url: '/#',
  },
  {
    name: 'Rio Tinto',
    src: require('../../assets/logos/logo-rioTinto.png').default,
    url: '/#',
  },
  {
    name: 'Safran',
    src: require('../../assets/logos/logo-safran.png').default,
    url: '/#',
  },
  {
    name: 'Shutter Home',
    src: require('../../assets/logos/logo-shutterHome.png').default,
    url: '/#',
  },
  {
    name: 'Taubman',
    src: require('../../assets/logos/logo-taubman.png').default,
    url: '/#',
  },
  {
    name: 'Loreal',
    src: require('../../assets/logos/loreal_logo.png').default,
    url: '/#',
  },
  {
    name: 'Regerneron',
    src: require('../../assets/logos/regerneron_logo.png').default,
    url: '/#',
  },
  {
    name: 'Supervalu',
    src: require('../../assets/logos/supervalu_logo.png').default,
    url: '/#',
  },
  {
    name: 'Underarmor',
    src: require('../../assets/logos/underarmor_logo.png').default,
    url: '/#',
  },
  {
    name: 'Verizon',
    src: require('../../assets/logos/verizon_logo.png').default,
    url: '/#',
  },
];
