import React from 'react';
import { Box, Container, Typography, Grid, Button } from '@material-ui/core';
import { title, btnTitle } from '../../utils/constants/newsConstants';
import { blogContentList } from '../../utils/constants/blogContentList';
import NewsMedia from '../../components/news_media';
import { useStyles } from './style';
import { useHistory } from 'react-router-dom';

function News(props) {
  const history = useHistory();
  const classes = useStyles();

  const navigatePage = () => {
    history.push('/blog');
  };

  const getNewList = (list: any) => {
    let newList: any = [];
    let size = list.length;
    const contentOnPage = 6;
    if (size <= contentOnPage) {
      newList.push(list);
    } else {
      for (let i = 0; i < size; i += contentOnPage) {
        let temp: any = list.slice(i, i + contentOnPage);
        newList.push(temp);
      }
    }
    return newList;
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h1" component="h2" className={classes.title}>
        {title}
      </Typography>
      <Box className={classes.imgBox}>
        <Grid container spacing={4}>
          {getNewList(blogContentList)[0].map((card, index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6}>
              <NewsMedia card={card} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Button
        className={classes.btn}
        disableFocusRipple={true}
        disableRipple={true}
        onClick={() => navigatePage()}
      >
        {btnTitle}
      </Button>
    </Box>
  );
}

export default News;
