import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '70px 15%',
  },
  title: {
    color: '#f8b200',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
  logoBox: {
    filter: 'brightness(0.5)',
    textAlign: 'center',
  },
  typo: {
    fontWeight: 'bold',
  },
}));
