export const blogContentList = [];
//   {
//     id: 1,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['API', 'Blockchain', 'Blog', 'Case Studies', 'Case Studies + Profiles'],
//     active: true,
//   },
//   {
//     id: 2,
//     tittle: 'Project managers, effective communication and the art of the analogy',
//     image:
//       require('../../assets/blogs/headerimg/Surge-project-manager-analogy-feature-image-1024x276.png')
//         .default,
//     author: 'Rob Winkler',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Efficient communication is a primary function and necessary skill for a project manager. Without it, they cannot manage expectations between the development team and project stakeholders. Development teams and project...',
//     date: '5 April 2021',
//     tag: ['News', 'Press Releases', 'Project Management', 'python', 'QA'],
//     active: true,
//   },
//   {
//     id: 3,
//     tittle: 'The importance of creativity in technical roles',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Abby-Edwards-Thrive-Global-feature-image-1024x276.png')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       "A piano and standard laptop keyboard have just about the same number of keys, 88. As a classically trained pianist and product owner, Abby Edwards has moved from one keyboard to the other. But in this transition, she's never lost sight that both",
//     date: '10 Febrary 2021',
//     tag: ['Technical Resources + Perspectives', 'Testing'],
//     active: true,
//   },
//   {
//     id: 4,
//     tittle: 'How to prioritize work to deliver maximum business value',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Business-prioritization-feature-image-1024x276.png')
//         .default,
//     author: 'TJ Trujillo',
//     subject: 'Case Studies & Profiles',
//     detail:
//       '“Prioritization of work” is an important Agile concept. It helps ensure teams complete work that is most important to the business first. However, many people still conflate the prioritization of important work with work that delivers business.',
//     date: '27 January 2021',
//     tag: ['Press Releases', 'Project Management', 'python'],
//     active: true,
//   },
//   {
//     id: 5,
//     tittle: 'Three agile assessments to resolve organizational issues',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Organizational-agile-assessments-feature-image-1024x276.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Enterprises can save time and money while improving agility through organizational assessments. From learning to adoption to practice, agile journeys are often not smooth. As organizational structure becomes more intricate and layered, it becomes exponentially more complex to resolve issues at the organizational level. Here’s a look at the limitations of agile frameworks to overcome organization impediments, and how.',
//     date: '21 January 2021',
//     tag: [
//       'Technical Resources + Perspectives',
//       'Testing',
//       'User Experience (UX)',
//       'Workforce Development',
//     ],
//     active: true,
//   },
//   {
//     id: 6,
//     tittle: 'What makes a great QA engineer?',
//     image:
//       require('../../assets/blogs/headerimg/Surge-project-manager-analogy-feature-image-1024x276.png')
//         .default,
//     author: 'Cameron Volger',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'How to find great QA engineers who can keep problems from slipping into production before they impact your bottom line, brand reputation or development capabilities. What makes a great QA engineer? What qualities do they possess that elevate them above just average testers? We’ve compiled six traits of a great QA engineer. 1. Inquisitive Asks, “What if…?” to better understand the product or the requirements. Questions bad.',
//     date: '15 January 2021',
//     tag: ['Code Review', 'Code Tips', 'Company News'],
//     active: true,
//   },
//   {
//     id: 7,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['API', 'Blockchain', 'Blog', 'Case Studies', 'Case Studies + Profiles'],
//     active: true,
//   },
//   {
//     id: 8,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['News', 'Press Releases', 'Project Management', 'python', 'QA'],
//     active: true,
//   },
//   {
//     id: 9,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['Technical Resources + Perspectives', 'Testing'],
//     active: true,
//   },
//   {
//     id: 10,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['Press Releases', 'Project Management', 'python'],
//     active: true,
//   },
//   {
//     id: 11,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: [
//       'Technical Resources + Perspectives',
//       'Testing',
//       'User Experience (UX)',
//       'Workforce Development',
//     ],
//     active: true,
//   },
//   {
//     id: 12,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['Code Review', 'Code Tips', 'Company News'],
//     active: true,
//   },
//   {
//     id: 13,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['Technical Resources + Perspectives', 'Testing'],
//     active: true,
//   },
//   {
//     id: 14,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['Press Releases', 'Project Management', 'python'],
//     active: true,
//   },
//   {
//     id: 15,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik sSmith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: [
//       'Technical Resources + Perspectives',
//       'Testing',
//       'User Experience (UX)',
//       'Workforce Development',
//     ],
//     active: true,
//   },
//   {
//     id: 16,
//     tittle: 'Surge hires new CEO Minna Rhee',
//     image:
//       require('../../assets/blogs/headerimg/Surge-Digital-transformation-business-and-IT-partnership.jpg')
//         .default,
//     author: 'Erik Smith',
//     subject: 'Case Studies & Profiles',
//     detail:
//       'Surge has hired Minna Rhee as CEO. She has extensive history leading international sales organizations and driving revenue growth for digital and technology companies. Most recently, she was president of BORN Group where she oversaw over $100M in revenue. She has also served as chief revenue officer at frog, developing a scalable, revenue generating sales and marketing organization, and chief digital, product and marketing officer at SourceMedia.',
//     date: '8 June 2021',
//     tag: ['Code Review', 'Code Tips', 'Company News'],
//     active: true,
//   },
// ];
