import { makeStyles } from '@material-ui/core';
import { ITheme } from '../../../../theme';

export const tittleStyle = makeStyles((theme: ITheme) => ({
  containerBox: {
    margin: '60px auto 0px auto',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '40px auto 0px auto',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px auto 30px auto',
    },
  },
  tittleBox: {
    width: '65%',
    color: 'white',
    margin: '40px auto 80px auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '20px auto 40px auto',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '5px auto 10px auto',
    },
  },
  typoHeader: {
    color: '#f1e0c0',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 10px',
      fontSize: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 30px 20px',
      fontSize: '27px',
    },
  },
  typoBody: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  button: {
    margin: '0px 20% 40px 20%',
  },
  listContainer: {
    margin: '0 auto',
    maxWidth: '3000px',
    transform: 'translate3d(0, 0, 0)',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(248, 147,0,0.8)',
      borderRadius: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoList: {
    padding: '0px',
    top: 0,
    left: '-50%',
    transform: 'translate3d(0, 0, 0)',
    width: '500%',
    display: 'inline-block',
    [theme.breakpoints.down('md')]: {
      width: '700%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '900%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mover: {
    animation: '$moveSlideshow 300s',
    display: 'inline-block',
    animationIterationCount: 'infinite',
  },
  '@keyframes moveSlideshow': {
    '100%': {
      transform: 'translateX(-800%)',
    },
  },
}));
