import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: '50px 50px',
  },
  title: {
    color: '#f8b200',
    fontWeight: 'bold',
    paddingBottom: '50px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
  },
  imgBox: {},
  btn: {
    fontSize: '1.7rem',
    color: '#f8b200',
    fontWeight: 'bold',
    padding: '20px',
    '&:hover': {
      background: 'transparent',
      color: '#505050',
    },
  },
}));
