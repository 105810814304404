import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useStyles } from './style';
import { Container, Box, TextField, Typography, Button } from '@material-ui/core';

const validationSchema = yup.object({
  name: yup.string().required('First name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

function Form(props) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      comment: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
  });

  return (
    <Box className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.textBox}>
          <TextField
            id="name"
            name="name"
            variant="outlined"
            placeholder="Your Name"
            size="small"
            inputProps={{
              style: { fontSize: 14 },
            }}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : ''}
            fullWidth
          />
        </Box>
        <Box className={classes.textBox}>
          <TextField
            id="email"
            name="email"
            variant="outlined"
            placeholder="Your Email"
            size="small"
            inputProps={{
              style: { fontSize: 14 },
            }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : ''}
            fullWidth
          />
        </Box>
        <Box className={classes.textBox}>
          <TextField
            id="comment"
            name="comment"
            variant="outlined"
            placeholder="Comment"
            inputProps={{ style: { fontSize: 14 } }}
            multiline
            rows={6}
            value={formik.values.comment}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
        <Typography variant="body2" component="div">
          Upload Resume (pdf, doc, docx)
        </Typography>
        <Box className={classes.uploadBox}>
          <Button variant="outlined" component="label" className={classes.fileBtn}>
            Upload File
            <input type="file" hidden />
          </Button>
        </Box>
        <Box>
          <Button type="submit" variant="contained" fullWidth className={classes.submitBtn}>
            SEND REQUEST
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default Form;
