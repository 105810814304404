import React from 'react';
import { Box, Container } from '@material-ui/core';
import WhoWeAre from './Components/WhoWeAre';
import CapabilitiesBar from './Components/CapabilitiesBar';

function index() {
  return (
    <Box>
      <Box>
        <WhoWeAre />
      </Box>
      <CapabilitiesBar />
    </Box>
  );
}

export default index;
