import React from 'react';
import { Box, Container, Typography, Grid } from '@material-ui/core';
import { title, boldText, test, listSr1, listSr2 } from '../../../utils/constants/careersConstants';
import { useStyles } from './style';

function TextForm(props) {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h2" component="h3" className={classes.title}>
        {test[2].title}
      </Typography>
      <Typography variant="body1" component="div">
        {test[2].body1}
      </Typography>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item>
          <Box>
            {listSr1.map((item, index) => (
              <ul key={index}>
                <li>
                  <Typography variant="body2" component="div">
                    {item}
                  </Typography>
                </li>
              </ul>
            ))}
          </Box>
        </Grid>
        <Grid item>
          {listSr2.map((item, index) => (
            <ul key={index}>
              <li>
                <Typography variant="body2" component="div">
                  {item}
                </Typography>
              </li>
            </ul>
          ))}
        </Grid>
      </Grid>
      <Typography variant="body1" className={classes.boldItalic}>
        {test[2].bold}
      </Typography>
      <Typography>{test[2].body2}</Typography>
    </Box>
  );
}

export default TextForm;
