import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { newsMediaStyle } from './style';
interface IProps {
  card: any;
}

export default function NewsMedia(props: IProps) {
  const classes = newsMediaStyle();
  return (
    <Box className={classes.containerBox}>
      <Box>
        <img className={classes.headerImage} src={props.card.image} alt="img" />
      </Box>
      <Box className={classes.contentBox}>
        <Box className={classes.textBox}>
          <Box className={classes.typoBox1}>
            <Link className={classes.textLink} to="/#">
              <Typography className={classes.typoTittle} variant="h5" component="h1">
                {props.card.tittle}
              </Typography>
            </Link>
            <Box>
              <Typography className={classes.typoDate} variant="h5" component="h1">
                {props.card.date}
              </Typography>
              <Link className={classes.textLink} to="/#">
                <Typography className={classes.typoAuthor} variant="h5" component="h1">
                  {props.card.author}
                </Typography>
              </Link>
            </Box>
          </Box>
          <Box className={classes.typoBox2}>
            <Typography className={classes.typoDetail} variant="h5" component="h1">
              {props.card.detail}
            </Typography>
            <Link className={classes.textLink} to="/#">
              <Typography className={classes.typoMore} variant="h5" component="h1">
                Read More
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
