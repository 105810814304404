import { Box, List, ListItem, ListItemIcon, Typography, Grid } from '@material-ui/core';
import OrangeButton from 'components/Button/OrangeButton';
import { LogoGenerate } from 'components/logo_genarate';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { buttonTitle } from 'utils/constants/buttonConstants';
import { tittleStyle } from './style';
import { useHistory } from 'react-router-dom';

export default function Tittle() {
  const location = useLocation();
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState('/home');
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);
  const classes = tittleStyle();

  const onNavigationHandler = (path: string) => {
    history.push(path);
    window.location.reload();
  };

  return (
    <Box>
      {currentPath === '/home' && (
        <Box className={classes.containerBox}>
          <Box>
            <Box className={classes.tittleBox}>
              <Typography className={classes.typoHeader} variant="h1" component={'h1'}>
                ACCESS TO VIETNAM SOFTWARE ENGINEERING TALENT, WHEN AND HOW YOU NEED IT
              </Typography>
            </Box>
            <Box className={classes.button}>
              <Grid container spacing={5}>
                {buttonTitle.map((item, index) => (
                  <Grid item md={12} xs={12} key={index}>
                    <OrangeButton
                      src={item.logo}
                      name={item.name}
                      onClick={() => onNavigationHandler(item.navigate)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box className={classes.listContainer}>
              <Box>
                <LogoGenerate />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {currentPath === '/contact' && (
        <Box className={classes.containerBox}>
          <Box className={classes.tittleBox}>
            <Typography className={classes.typoHeader} variant="h1" component={'h1'}>
              CONTACT US
            </Typography>
          </Box>
        </Box>
      )}
      {currentPath === '/hire-a-developer' && (
        <Box className={classes.containerBox}>
          <Box className={classes.tittleBox}>
            <Typography className={classes.typoHeader} variant="h1" component={'h1'}>
              HIRE VIETNAM’S BEST TALENT
            </Typography>
          </Box>
        </Box>
      )}
      {currentPath === '/careers' && (
        <Box className={classes.containerBox}>
          <Box className={classes.tittleBox}>
            <Typography className={classes.typoHeader} variant="h1" component={'h1'}>
              JOIN BELLA GARDEN’S NETWORK OF TOP SOFTWARE ENGINEERS
            </Typography>
          </Box>
        </Box>
      )}
      {currentPath === '/capabilities' && (
        <Box className={classes.containerBox}>
          <Box className={classes.tittleBox}>
            <Typography className={classes.typoHeader} variant="h1" component={'h1'}>
              CAPABILITIES
            </Typography>
          </Box>
        </Box>
      )}
      {currentPath === '/blog/all' && (
        <Box className={classes.containerBox}>
          <Box className={classes.tittleBox}>
            <Typography className={classes.typoHeader} variant="h1" component={'h1'}>
              BELLA GARDEN BLOG
            </Typography>
            <Typography variant="h4" component={'h1'} className={classes.typoBody}>
              The Bella Garden Blog is the top hub for developers, executives and entrepreneurs,
              featuring key technology updates, freelancer resources and management insights.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
