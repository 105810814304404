import React, { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { styled } from '@material-ui/core/styles';
import { ImageListItemBar, Typography, Grid } from '@material-ui/core';
import { useStyles } from './style';
import { resources, title } from '../../../../utils/constants/localeConstants';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const StyledImageListItemBar = styled(ImageListItemBar)({
  '& .MuiImageListItemBar-title': {
    fontSize: '1.5rem',
  },
});

const StyledImageListItem = styled(ImageListItem)(({ theme }) => ({
  '&. .MuiImageListItem-item': {
    height: '70%',
  },
}));

function RemoteResources() {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  return (
    <Box className={classes.root}>
      <Typography
        style={{ color: '#fff' }}
        variant="body1"
        component="div"
        className={classes.resourcesText}
      >
        {title}
      </Typography>
      <ImageList className={classes.imgItem}>
        {resources.map((item, index) => (
          <Grid item sm={6} xs={6} md={3} key={index}>
            <img src={item.img} alt={item.name} className={classes.img} loading="lazy" />
            <Box>
              <Typography
                style={{ color: '#fff' }}
                variant="body1"
                component="div"
                className={classes.imgText}
              >
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </ImageList>
    </Box>
  );
}

export default RemoteResources;
