import angular from '../../assets/TechLogo/Angular-Logo-Only.png';
import blazor from '../../assets/TechLogo/blazor.png';
import go from '../../assets/TechLogo/Go-Logo-new.png';
import graphql from '../../assets/TechLogo/GraphQL_Logo.png';
import java from '../../assets/TechLogo/Java.png';
import js from '../../assets/TechLogo/JavaScript.png';
import laravel from '../../assets/TechLogo/Laravel.png';
import nodejs from '../../assets/TechLogo/Node-JS.png';
import python from '../../assets/TechLogo/Python-logo-only.png';
import ror from '../../assets/TechLogo/Rails.png';
import react from '../../assets/TechLogo/React.png';
import scala from '../../assets/TechLogo/Scala-full-color.png';
import vue from '../../assets/TechLogo/Vue.js_Logo.png';
import hadoop from '../../assets/TechLogo/hadoop.png';
import kafka from '../../assets/TechLogo/Kafka-TALL.png';
import azure from '../../assets/TechLogo/microsoft-azureicon.png';
import sql from '../../assets/TechLogo/microsoft-sql-server.png';
import mongo from '../../assets/TechLogo/mongodb.png';
import mysql from '../../assets/TechLogo/MySQL.png';
import oracle from '../../assets/TechLogo/Oracle.png';
import postgre from '../../assets/TechLogo/PostgreSQL.png';
import rabbit from '../../assets/TechLogo/RabbitMQ_logo.png';
import redis from '../../assets/TechLogo/redis-official.png';
import android from '../../assets/TechLogo/Android_symbol_RGB.png';
import apple from '../../assets/TechLogo/Apple.png';
import flutter from '../../assets/TechLogo/flutter.png';
import phonegap from '../../assets/TechLogo/PhoneGap-Stacked-Black.png';
import xamarin from '../../assets/TechLogo/Xamarin-icon-only.png';
import electron from '../../assets/TechLogo/electronjs-icon.png';
import cplus from '../../assets/TechLogo/c.png';
import csharp from '../../assets/TechLogo/C-Sharp.png';
import net from '../../assets/TechLogo/asp-dot-net-wide.png';
import swift from '../../assets/TechLogo/swift.png';
import aws from '../../assets/TechLogo/amazon-web-services-aws.png';
import ansible from '../../assets/TechLogo/ansible.png';
import chef from '../../assets/TechLogo/chef-logo.png';
import docker from '../../assets/TechLogo/docker-logo.png';
import jenkins from '../../assets/TechLogo/jenkins-logo.png';
import kubernetes from '../../assets/TechLogo/Kubernetes-Logo.wine_.png';
import puppet from '../../assets/TechLogo/Puppet_black_yellow_on_white.png';
import terra from '../../assets/TechLogo/terraform_logo.png';
import cucumber from '../../assets/TechLogo/cucumber.png';
import cypress from '../../assets/TechLogo/cypress-io-logo.png';
import gatling from '../../assets/TechLogo/gatling.png';
import apache from '../../assets/TechLogo/jmeter.png';
import selenium from '../../assets/TechLogo/Selenium_Logo.png';

export const title = ['CORE TECHNICAL COMPETENCIES'];

export const tabPanels = ['Web/Cloud', 'Data', 'Mobile', 'Desktop', 'DevOps'];

export const webTechnicals = [
  {
    name: 'Angular',
    img: angular,
    navigate: '/angular-developers',
    title1: 'HIRE ANGULAR JS DEVELOPERS',
    title2: 'Hire onshore senior Angular developer',
    body: 'Bella Garden has Vietnam’s best onshore Angular developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Blazor',
    img: blazor,
    navigate: '/blazor-developers',
    title1: 'HIRE BLAZOR DEVELOPERS',
    title2: 'Hire onshore senior Blazor developer',
    body: 'Bella Garden has Vietnam’s best onshore Blazor developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Go',
    img: go,
    navigate: '/golang-developers',
    title1: 'HIRE GOLANG DEVELOPERS',
    title2: 'Hire onshore senior Golang developer',
    body: 'Bella Garden has Vietnam’s best onshore Golang developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'GraphQL',
    img: graphql,
    navigate: '/graphql-developers',
    title1: 'HIRE GRAPH QL DEVELOPERS',
    title2: 'Hire onshore senior GraphQL developer',
    body: 'Bella Garden has Vietnam’s best onshore GraphQL developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Java',
    img: java,
    navigate: '/java-developers',
    title1: 'HIRE JAVA DEVELOPERS',
    title2: 'Hire onshore senior Java developer',
    body: 'Bella Garden has Vietnam’s best onshore Java developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'JavaScript',
    img: js,
    navigate: '/js-developers',
    title1: 'HIRE JAVASCRIPT DEVELOPERS',
    title2: 'Hire onshore senior JavaScript developer',
    body: 'Bella Garden has Vietnam’s best onshore JavaScript developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Laravel',
    img: laravel,
    navigate: '/laravel-developers',
    title1: 'HIRE LARAVEL DEVELOPERS',
    title2: 'Hire onshore senior Laravel developer',
    body: 'Bella Garden has Vietnam’s best onshore Laravel developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'NodeJS',
    img: nodejs,
    navigate: '/node-js-developers',
    title1: 'HIRE NODE JS DEVELOPERS',
    title2: 'Hire onshore senior NodeJS developer',
    body: 'Bella Garden has Vietnam’s best onshore NodeJS developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Python',
    img: python,
    navigate: '/python-developers',
    title1: 'HIRE PYTHON DEVELOPERS',
    title2: 'Hire onshore senior Python developer',
    body: 'Bella Garden has Vietnam’s best onshore Python developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'React',
    img: react,
    navigate: '/react-developers',
    title1: 'HIRE REACT JS DEVELOPERS',
    title2: 'Hire onshore senior React developer',
    body: 'Bella Garden has Vietnam’s best onshore React developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Ruby on Rails',
    img: ror,
    navigate: '/ruby-on-rails-developers',
    title1: 'HIRE RUBY ON RAILS DEVELOPERS',
    title2: 'Hire onshore senior Ruby on Rails developer',
    body: 'Bella Garden has Vietnam’s best onshore Ruby on Rails developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Scala',
    img: scala,
    navigate: '/scala-developers',
    title1: 'HIRE SCALA DEVELOPERS',
    title2: 'Hire onshore senior Scala developer',
    body: 'Bella Garden has Vietnam’s best onshore Scala developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Vue',
    img: vue,
    navigate: '/vue-js-developers',
    title1: 'HIRE VUE JS DEVELOPERS',
    title2: 'Hire onshore senior Vue developer',
    body: 'Bella Garden has Vietnam’s best onshore Vue JS developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
];

export const dataTechnicals = [
  {
    name: 'Hadoop',
    img: hadoop,
    navigate: '/hadoop-developers',
    title1: 'HIRE HADOOP DEVELOPERS',
    title2: 'Hire onshore senior Hadoop developer',
    body: 'Bella Garden has Vietnam’s best onshore Hadoop developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Kafka',
    img: kafka,
    navigate: '/kafka-developers',
    title1: 'HIRE KAFKA DEVELOPERS',
    title2: 'Hire onshore senior Kafka developer',
    body: 'Bella Garden has Vietnam’s best onshore Kafka developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Microsoft Azure',
    img: azure,
    navigate: '/microsoft-azure-developers',
    title1: 'HIRE MICROSOFT AZURE DEVELOPERS',
    title2: 'Hire onshore senior Microsoft Azure developer',
    body: 'Bella Garden has Vietnam’s best onshore Microsoft Azure developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Microsoft SQL Server',
    img: sql,
    navigate: '/microsoft-sql-server-developers',
    title1: 'HIRE MICROSOFT SQL SERVER DEVELOPERS',
    title2: 'Hire onshore senior Microsoft SQL Server developer',
    body: 'Bella Garden has Vietnam’s best onshore Microsoft SQL Server developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'MongoDB',
    img: mongo,
    navigate: '/mongodb-developers',
    title1: 'HIRE MONGODB DEVELOPERS',
    title2: 'Hire onshore senior MongoDB developer',
    body: 'Bella Garden has Vietnam’s best onshore MongoDB developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'MySQL',
    img: mysql,
    navigate: '/mysql-developers',
    title1: 'HIRE MYSQL DEVELOPERS',
    title2: 'Hire onshore senior MySQL developer',
    body: 'Bella Garden has Vietnam’s best onshore MySQL developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Oracle',
    img: oracle,
    navigate: '/oracle-developers',
    title1: 'HIRE ORACLE DEVELOPERS',
    title2: 'Hire onshore senior Oracle developer',
    body: 'Bella Garden has Vietnam’s best onshore Oracle developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'PostgreSQL',
    img: postgre,
    navigate: '/postgresql-developers',
    title1: 'HIRE POSTGRESQL DEVELOPERS',
    title2: 'Hire onshore senior PostgreSQL developer',
    body: 'Bella Garden has Vietnam’s best onshore PostgreSQL developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'RabbitMQ',
    img: rabbit,
    navigate: '/rabbitmq-developers',
    title1: 'HIRE RABBITMQ DEVELOPERS',
    title2: 'Hire onshore senior RabbitMQ developer',
    body: 'Bella Garden has Vietnam’s best onshore RabbitMQ developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Redis',
    img: redis,
    navigate: '/redis-developers',
    title1: 'HIRE REDIS DEVELOPERS',
    title2: 'Hire onshore senior Redis developer',
    body: 'Bella Garden has Vietnam’s best onshore Redis developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
];

export const mobileTechnicals = [
  {
    name: 'Android',
    img: android,
    navigate: '/android-developers',
    title1: 'HIRE ANDROID DEVELOPERS',
    title2: 'Hire onshore senior Android developer',
    body: 'Bella Garden has Vietnam’s best onshore Android developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Apple',
    img: apple,
    navigate: '/apple-developers',
    title1: 'HIRE APPLE DEVELOPERS',
    title2: 'Hire onshore senior Apple developer',
    body: 'Bella Garden has Vietnam’s best onshore Apple developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Flutter',
    img: flutter,
    navigate: '/flutter-developers',
    title1: 'HIRE FLUTTER DEVELOPERS',
    title2: 'Hire onshore senior Flutter developer',
    body: 'Bella Garden has Vietnam’s best onshore Flutter developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Adobe Phonegap',
    img: phonegap,
    navigate: '/adobe-phonegap-developers',
    title1: 'HIRE ADOBE PHONEGAP DEVELOPERS',
    title2: 'Hire onshore senior Adobe Phonegap developer',
    body: 'Bella Garden has Vietnam’s best onshore Adobe Phonegap developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'React',
    img: react,
    navigate: '/react-developers',
    title1: 'HIRE REACT JS DEVELOPERS',
    title2: 'Hire onshore senior React developer',
    body: 'Bella Garden has Vietnam’s best onshore React developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Xamarin',
    img: xamarin,
    navigate: '/xamarin-developers',
    title1: 'HIRE XAMARIN JS DEVELOPERS',
    title2: 'Hire onshore senior Xamarin developer',
    body: 'Bella Garden has Vietnam’s best onshore Xamarin developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
];

export const desktopTechnicals = [
  {
    name: 'Electron',
    img: electron,
    navigate: '/electron-developers',
    title1: 'HIRE ELECTRON DEVELOPERS',
    title2: 'Hire onshore senior Electron developer',
    body: 'Bella Garden has Vietnam’s best onshore Electron developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'C++',
    img: cplus,
    navigate: '/cplusplus-developers',
    title1: 'HIRE C++ DEVELOPERS',
    title2: 'Hire onshore senior C++ developer',
    body: 'Bella Garden has Vietnam’s best onshore C++ developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'C#',
    img: csharp,
    navigate: '/csharp-developers',
    title1: 'HIRE C# DEVELOPERS',
    title2: 'Hire onshore senior C# developer',
    body: 'Bella Garden has Vietnam’s best onshore C# developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Microsoft .NET',
    img: net,
    navigate: '/mircosoft.net-developers',
    title1: 'HIRE MICROSOFT .NET DEVELOPERS',
    title2: 'Hire onshore senior Microsoft .NET developer',
    body: 'Bella Garden has Vietnam’s best onshore Microsoft .NET developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Swift',
    img: swift,
    navigate: '/swift-developers',
    title1: 'HIRE SWIFT DEVELOPERS',
    title2: 'Hire onshore senior Swift developer',
    body: 'Bella Garden has Vietnam’s best onshore Swift developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
];

export const devopsTechnicals = [
  {
    name: 'AWS',
    img: aws,
    navigate: '/aws-developers',
    title1: 'HIRE AWS DEVELOPERS',
    title2: 'Hire onshore senior AWS developer',
    body: 'Bella Garden has Vietnam’s best onshore AWS developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Ansible',
    img: ansible,
    navigate: '/ansible-developers',
    title1: 'HIRE ANSIBLE DEVELOPERS',
    title2: 'Hire onshore senior Ansible developer',
    body: 'Bella Garden has Vietnam’s best onshore Ansible developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Chef',
    img: chef,
    navigate: '/chef-developers',
    title1: 'HIRE CHEF DEVELOPERS',
    title2: 'Hire onshore senior Chef developer',
    body: 'Bella Garden has Vietnam’s best onshore Chef developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Docker',
    img: docker,
    navigate: '/docker-developers',
    title1: 'HIRE DOCKER DEVELOPERS',
    title2: 'Hire onshore senior Docker developer',
    body: 'Bella Garden has Vietnam’s best onshore Docker developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Jenkins',
    img: jenkins,
    navigate: '/jenkins-developers',
    title1: 'HIRE JENKINS DEVELOPERS',
    title2: 'Hire onshore senior Jenkins developer',
    body: 'Bella Garden has Vietnam’s best onshore Jenkins developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Kubernetes',
    img: kubernetes,
    navigate: '/kubernetes-developers',
    title1: 'HIRE KUBERNETES DEVELOPERS',
    title2: 'Hire onshore senior Kubernetes developer',
    body: 'Bella Garden has Vietnam’s best onshore Kubernetes developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Puppet',
    img: puppet,
    navigate: '/puppet-developers',
    title1: 'HIRE PUPPET DEVELOPERS',
    title2: 'Hire onshore senior Puppet developer',
    body: 'Bella Garden has Vietnam’s best onshore Puppet developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Terraform',
    img: terra,
    navigate: '/terraform-developers',
    title1: 'HIRE TERRAFORM DEVELOPERS',
    title2: 'Hire onshore senior Terraform developer',
    body: 'Bella Garden has Vietnam’s best onshore Terraform developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
];

export const qaTechnicals = [
  {
    name: 'Cucumber',
    img: cucumber,
    navigate: '/cucumber-developers',
    title1: 'HIRE CUCUMBER DEVELOPERS',
    title2: 'Hire onshore senior Cucumber developer',
    body: 'Bella Garden has Vietnam’s best onshore Cucumber developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Cypress.io',
    img: cypress,
    navigate: '/cypress-developers',
    title1: 'HIRE CYPRESS.IO DEVELOPERS',
    title2: 'Hire onshore senior Cypress.io developer',
    body: 'Bella Garden has Vietnam’s best onshore Cypress.io developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Gatling',
    img: gatling,
    navigate: '/gatling-developers',
    title1: 'HIRE GATLING DEVELOPERS',
    title2: 'Hire onshore senior Gatling developer',
    body: 'Bella Garden has Vietnam’s best onshore Gatling developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Apache JMeter',
    img: apache,
    navigate: '/apache-developers',
    title1: 'HIRE APACHE JMETER DEVELOPERS',
    title2: 'Hire onshore senior Apache JMeter developer',
    body: 'Bella Garden has Vietnam’s best onshore Apache JMeter developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
  {
    name: 'Selenium',
    img: selenium,
    navigate: '/selenium-developers',
    title1: 'HIRE SELENIUM DEVELOPERS',
    title2: 'Hire onshore senior Selenium developer',
    body: 'Bella Garden has Vietnam’s best onshore Selenium developers and we offer them on-demand to our clients. With an attrition rate of less than 5% (that’s better than Google), we have some of the happiest and most productive developers that you’ll come across. Our specialties are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between.',
  },
];
