import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core';
import { theme } from 'theme';
import RootRouter from '../src/router/RootRouter';

function App() {
  return (
    <div>
      <RootRouter />
    </div>
  );
}

export default App;
