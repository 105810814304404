import { Box, CardMedia, Link, List } from '@material-ui/core';
import React from 'react';
import { sponsorLogoList } from '../../utils/constants/sponsorLogoList';
import { LogoGenerateList } from '../logo_genarate/style';
import { useStyles } from '../logo_genarate/style';

export const LogoGenerate = () => {
  const classes = useStyles();

  return (
    <Box>
      {sponsorLogoList.map((element, index) => {
        return (
          <LogoGenerateList key={index}>
            <Link href={element.url}>
              <CardMedia
                className={classes.img}
                component="img"
                height="auto"
                image={element.src}
                alt={element.name}
              />
            </Link>
          </LogoGenerateList>
        );
      })}
    </Box>
  );
};
