export const sizeList = [
  {
    value: '1-9 Employees',
  },
  {
    value: '10-100 Employees',
  },
  {
    value: '101-500 Employees',
  },
  {
    value: '501-1,000 Employees',
  },
  {
    value: '1,001-5,000 Employees',
  },
  {
    value: '5,001-10,000 Employees',
  },
  {
    value: '10,000+ Employees',
  },
];
