import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ReactPlayer from 'react-player';
import { useStyles } from './style';
import video from '../../assets/video/test.mp4';

function VideoPlayer({ width, height }) {
  const classes = useStyles();

  return (
    <div className={classes.videoWrapper}>
      <ReactPlayer
        url="https://vimeo.com/243556536"
        width={width}
        height={height}
        className={classes.reactPlayer}
        playing={false}
      />
    </div>
  );
}

export default VideoPlayer;
