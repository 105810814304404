import { makeStyles, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 375,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  iconList: {
    fontSize: '14px',
    padding: '20px',
    letterSpacing: '10px',
  },
  root: {
    backgroundImage: `url(${
      require('../../../../assets/background/backgroundFooter.svg').default
    })`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    background: '#505050',
    color: 'white',
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 300,
    marginTop: '-5px',
    padding: '50px 0px',
    textAlign: 'center',
  },
  buttonList: {
    color: '#fff',
    height: '16px',
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '16px',
    display: 'inline-flex',
    cursor: 'pointer',
    padding: '30px',
    '&:hover': {
      color: '#f8b200',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '25px 20px',
    },
  },
  infoList: {
    color: '#fff',
    display: 'inline-flex',
    padding: ' 0px 20px',
    fontSize: '1.2rem',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
      borderRight: '0px solid black',
    },
  },
  addressTitle: {
    padding: '25px',
    fontSize: '1.2rem',
  },
  imgBox: {
    fontWeight: 700,
    fontStyle: 'italic',
    fontSize: '2.6rem',
    paddingBottom: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logoBox: {
    display: 'inline-flex',
    margin: 'auto',
  },
  clutch: {
    fontSize: '1.1rem',
  },
  reviews: {
    color: '#fff',
    fontSize: '1.4rem',
  },
  logoItem: {
    padding: '0px 10px',
  },
  buttonGroup: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    padding: '0px',
  },
  btn: {
    padding: '0px',
  },
  linkText: {
    textDecoration: 'none',
    color: 'white',
    padding: '5px',
    '&:hover': {
      color: '#f8b200',
    },
  },
}));
