import React from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { mobileTechnicals } from '../../../utils/constants/coreTechnicalConstants';
import { useStyles } from './style';
import { useHistory, Link } from 'react-router-dom';

interface IProps {
  name: string;
  img: string;
  navigate: string;
}

function Mobile(props) {
  const classes = useStyles();
  const history = useHistory();

  const onNavigationHandler = (path: string) => {
    history.push(path);
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      {mobileTechnicals.map((item: IProps, index: number) => (
        <Link key={index} className={classes.linkText} to={`/developers/${item.name}`}>
          <Box key={index} className={classes.box}>
            <img src={item.img} alt="" className={classes.img}></img>
            <Typography>{item.name}</Typography>
          </Box>
        </Link>
      ))}
    </Container>
  );
}

export default Mobile;
