import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '80px 0px',
    textAlign: 'center',
  },
  title: {
    fontSize: '3.6rem',
    fontWeight: 'bold',
    color: '#505050',
    paddingBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
    },
  },
  tab: {
    fontSize: '1.2rem',
    width: '100px',
  },
  tabList: {
    justifyContent: 'center',
  },
  scroller: {
    flexGrow: 0,
  },
  tabRoot: {
    justifyContent: 'center',
  },
  tabScroll: {
    flexGrow: 0,
  },
}));
