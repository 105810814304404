import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: '#393939',
    border: 0,
    borderRadius: 0,

    padding: '50px 0px',
    fontSize: '2rem',
    textAlign: 'center',
  },
  imgItem: {
    height: '50%',
    width: '100%',
    padding: '20px 0px',
    margin: 0,
    alignItems: 'center',
  },
  img: {
    height: '128px',
    width: '128px',
    display: 'block',
    margin: 'auto',
  },
  imgBox: {
    display: 'inline-flex',
  },
  imgText: {
    marginTop: '15px',
  },
  resourcesText: {
    padding: '0px 20px',
    fontWeight: 'bold',
  },
}));
