/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import { title, content } from '../../utils/constants/engageConstants';
import { useStyles } from './style';

function index() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.titleBox}>
        <Typography variant="h2" component="h1">
          {title}
        </Typography>
      </Box>
      <Grid container spacing={5}>
        {content.map((item, index) => (
          <Grid item lg={6} md={12} key={index}>
            <Box className={classes.gridItem}>
              <img src={item.logo} alt="" className={classes.img}></img>
              <Box className={classes.textBox}>
                <Typography variant="h4" component="h1">
                  {item.title}
                </Typography>
                <Typography variant="body2" component="h1">
                  {item.content}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default index;
