import { makeStyles } from '@material-ui/core';
import { ITheme } from '../../theme';

export const contactStyles = makeStyles((theme: ITheme) => ({
  containerBox: {
    width: '95%',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    padding: '40px 0px',
    backgroundColor: '#F8F8FA',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0px',
    },
  },
  sponsorBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '10px',
    filter: 'invert(60%)',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: 'auto',
    },
  },
  typographyBox: {
    height: 'fit-content',
    filter: 'none !important',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px 5px 10px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5px 5px 5px 10px',
    },
  },
  employeeBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px',
    backgroundColor: '#F8F8FA',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  textField: {
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        fill: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3ba8ff',
      },
    },
  },
  fieldHandle: {
    margin: '4px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  submitButton: {
    width: '50%',
    padding: '15px',
    margin: '5px',
    color: '#fff',
    fontSize: '15px',
    border: '2px solid white',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#ff9700',
    '&:hover': {
      backgroundColor: '#ec8c00',
    },
    '&:active': {
      backgroundColor: '#ec8c00',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '5px 5px 5px 20px',
      width: '95%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '91%',
      margin: '5px 0px 5px 10px',
    },
  },
  fieldBox: {
    margin: '5px 10px 5px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
    },
  },
  textBoxHandle: {
    backgroundColor: 'white',
  },
  formBox: {
    margin: '0px 0px 200px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
    },
  },
  center: {
    justifyContent: 'center',
    textAlign: 'center',
    paddingBottom: '50px',
  },
  bodyText: {
    fontSize: '2rem',
    padding: '20px 0px',
  },
  titleText: {
    fontSize: '3rem',
    fontWeight: 700,
  },
}));
