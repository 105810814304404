import React from 'react';
import { Box, Typography, Button, Container } from '@material-ui/core';
import { capabilities } from '../../../utils/constants/capabilitiesConstant';
import { useStyles } from './style';
import { useHistory } from 'react-router-dom';

function Capabilities() {
  const classes = useStyles();
  const history = useHistory();

  const onNavigationHandler = () => {
    return history.push('/capabilities');
  };

  return (
    <div className={classes.bar}>
      <Box>
        <Button
          className={classes.btnCapabilities}
          disableFocusRipple={true}
          disableElevation={true}
          disableRipple={true}
          onClick={() => onNavigationHandler()}
        >
          LEARN MORE ABOUT OUR CAPABILITIES
        </Button>
        <div className={classes.logoItemBar}>
          {capabilities.map((item, index) => (
            <Box className={classes.logoItem} key={index}>
              <img src={item.logo} alt="" className={classes.img}></img>
              <Typography key={index} className={classes.typoText}>
                {item.name}
              </Typography>
            </Box>
          ))}
        </div>
      </Box>
    </div>
  );
}

export default Capabilities;
