import Baltimore from '../../assets/FooterLogo/Baltimore.png';
import Boston from '../../assets/FooterLogo/Boston.png';
import Chicago from '../../assets/FooterLogo/Chicago.png';
import Denver from '../../assets/FooterLogo/Denver.png';
import Portland from '../../assets/FooterLogo/Portland.png';
import Scottsdale from '../../assets/FooterLogo/Scottsdale.png';

export const resources = [
  {
    name: 'NEW YORK',
    img: Chicago,
  },
  {
    name: 'CHEYENNE',
    img: Boston,
  },
  {
    name: 'VIETNAM',
    img: Denver,
  },
  {
    name: 'LOS ANGELES',
    img: Portland,
  },
];

export const title =
  'Our remote resources are dispersed across time zones and we operate development centers in:';
