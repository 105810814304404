import { Box, Button, Container, Grid, List, ListItem, Typography } from '@material-ui/core';
import NewsMedia from '../../components/news_media';
import React, { useEffect, useState } from 'react';
import FilterCard from '../../components/filter_card';
import { filterBoxList, tagList } from '../../utils/constants/filterList';
import { blogStyle } from './style';
import { blogContentList } from '../../utils/constants/blogContentList';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const Blog = (props) => {
  const classes = blogStyle();
  const [page, setMyPage] = useState(1);
  const setPage = (e, p) => {
    setMyPage(p);
  };
  const getNewList = (list: any) => {
    let newList: any = [];
    let size = list.length;
    const contentOnPage = 6;
    if (size <= contentOnPage) {
      newList.push(list);
    } else {
      for (let i = 0; i < size; i += contentOnPage) {
        let temp: any = list.slice(i, i + contentOnPage);
        newList.push(temp);
      }
    }
    return newList;
  };
  const filterList = (list: any) => {
    let newList: any = [];
    list.map((element) => {
      if (element.tag.includes(props.match.params.id)) {
        newList.push(element);
      }
    });
    console.log(newList);
    return newList;
  };

  return (
    <Box className={classes.containerBox}>
      <Container maxWidth="xl">
        {props.match.params.id === 'all' && (
          <>
            <Box className={classes.topFilerBox}>
              <Grid container spacing={1}>
                {filterBoxList.map((filter, index) => (
                  <Grid
                    style={{ margin: 'auto' }}
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                  >
                    <FilterCard filter={filter} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Container maxWidth="lg">
              <Box className={classes.blogList1}>
                <Grid container spacing={3}>
                  {getNewList(blogContentList)[page - 1].map((card, index) => (
                    <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <NewsMedia card={card} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
            <Box className={classes.pagination}>
              <Pagination
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                color="secondary"
                size="small"
                page={page}
                onChange={setPage}
                count={getNewList(blogContentList).length}
              />
            </Box>
          </>
        )}
        {props.match.params.id !== 'all' && (
          <>
            <Container maxWidth="md">
              <Box className={classes.blogList2}>
                <Grid container spacing={3}>
                  {getNewList(filterList(blogContentList))[page - 1].map((card, index) => (
                    <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <NewsMedia card={card} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
            <Box className={classes.pagination}>
              <Pagination
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                color="secondary"
                size="small"
                page={page}
                onChange={setPage}
                count={getNewList(filterList(blogContentList)).length}
              />
            </Box>
          </>
        )}
      </Container>
      <Box className={classes.borderBottomFilter}>
        <Box className={classes.bottomFilter}>
          <Box className={classes.filterList}>
            {tagList.map((tag, index) => (
              <Button className={classes.tag} key={index} disableRipple>
                <li className={classes.tagLi}>
                  <Link className={classes.tagLink} to={`/blog/${tag}`}>
                    {tag}
                  </Link>
                </li>
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(Blog);
