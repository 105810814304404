import React from 'react';
import { Box, Container, TextField, List, Typography, Grid } from '@material-ui/core';
import { LogoGenerate } from '../../components/logo_genarate';
import HowWeEngage from '../HowWeEngage';
import CoreTech from '../../features/CoreTech';
import { useStyles } from '../Developers/style';
import Form from './form';
import test from '../../assets/background/coders.png';
import { withRouter } from 'react-router-dom';

function Developers(props: any) {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={4} className={classes.center}>
          <Grid item xl={8} lg={6} md={12}>
            <Box>
              <Typography variant="h2" component="div" className={classes.title1}>
                {props.title1}
              </Typography>
              <Typography variant="h3" component="div" className={classes.title2}>
                {props.title2}
              </Typography>
              <Typography variant="body1" component="div" className={classes.body}>
                {props.body}
              </Typography>
            </Box>
            {/* <Box>
              <img src={props.img} alt=""></img>
            </Box> */}
          </Grid>
          <Grid item xl={4} lg={6} md={12} className={classes.zindex}>
            <Form />
            <Box className={classes.background}></Box>
          </Grid>
        </Grid>
      </Container>
      <Box className={classes.listContainer}>
        <List className={classes.logoList}>
          <Box className={classes.mover}>
            <LogoGenerate />
          </Box>
        </List>
      </Box>
      <HowWeEngage />
      <CoreTech />
    </>
  );
}

export default withRouter(Developers);
