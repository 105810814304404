import WhoWeAre from '../who_we_are';
import HowWeEngage from '../HowWeEngage';
import Comments from '../Comments';
import React from 'react';
import News from '../News';
import CoreTech from '../CoreTech';
import { blogContentList } from '../../utils/constants/blogContentList';

const Home = () => {
  return (
    <>
      <WhoWeAre />
      <HowWeEngage />
      {blogContentList.length > 0 ? <News /> : <div></div>}
      <CoreTech />
    </>
  );
};

export default Home;
