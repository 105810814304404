import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme';

export const newsMediaStyle = makeStyles((theme: ITheme) => ({
  containerBox: {
    height: '400px',
    position: 'relative',
    boxShadow: '0 3px 15px 3px rgb(0 0 0 / 20%)',
  },
  headerImage: {
    width: '100%',
    height: '150px',
  },
  contentBox: {
    position: 'absolute',
    backgroundImage: `url(${require('../../assets/background/backgroundFooter.svg').default})`,
    backgroundSize: '200%',
    top: '25%',
    width: '100%',
    height: '250px',
    padding: '50px 0px 0px 0px',
    clipPath: 'polygon(0 10%,115% 0,115% 115%,0 115%)',
    backgroundColor: '#f8b200',
    [theme.breakpoints.down('xs')]: {
      clipPath: 'none',
    },
  },
  textBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 15px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '-25px',
    },
  },
  typoBox1: {
    width: '40%',
    padding: '0px 10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  typoBox2: {
    width: '60%',
    padding: '0px 10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'justify',
    },
  },
  textLink: {
    textDecoration: 'none',
  },
  typoTittle: {
    fontSize: '1.5rem',
    color: 'white',
    fontWeight: 'bold',
    margin: '0px 0px 10% 0px',
    '&:hover': {
      color: 'black',
      transition: '0.5s',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0px 10px 0px',
      fontSize: '2rem',
    },
  },
  typoDate: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0px 0px 10px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0px 2px 0px',
    },
  },
  typoAuthor: {
    fontSize: '16px',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      color: 'black',
      transition: '0.5s',
    },
  },
  typoDetail: {
    height: '100px',
    overflow: 'hidden',
    fontSize: '15px',
    color: 'white',
    margin: '0px 0px 30px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 0px 2px 0px',
    },
  },
  typoMore: {
    fontSize: '16px',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      color: 'black',
      transition: '0.5s',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));
