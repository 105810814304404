import React from 'react';
import FooterEnd from './Components/FooterEnd/FooterEnd';
import RemoteResources from './Components/RemoteResources/RemoteResources';
import SurgeButton from './Components/SurgeButton/SurgeButton';
import SurgeFooter from './Components/SurgeFooter/SurgeFooter';

function index() {
  return (
    <div>
      <SurgeButton />
      <RemoteResources />
      <SurgeFooter />
      <FooterEnd />
    </div>
  );
}

export default index;
