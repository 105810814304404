/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import TextForm1 from './Components/TextForm1';
import TextForm2 from './Components/TextForm2';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { useStyles } from './style';
import { videoText } from '../../utils/constants/careersConstants';

function index(props) {
  const classes = useStyles();

  return (
    <Box>
      <Container className={classes.root} maxWidth="xl">
        <TextForm1 />
      </Container>
      <Box className={classes.videoBox}>
        <VideoPlayer width="100%" height="100%" />
        <Typography className={classes.typoText}>{videoText}</Typography>
      </Box>
      <Container className={classes.root} maxWidth="xl">
        <TextForm2 />
      </Container>
    </Box>
  );
}

export default index;
