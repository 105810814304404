import { makeStyles, createTheme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    background: '#f8b200',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${
      require('../../../../assets/background/backgroundFooter.svg').default
    })`,
    color: '#fff',
    textAlign: 'center',
    padding: '50px 20%',
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      padding: '40px 20%',
    },
  },
  title: {
    fontSize: '4rem',
    fontWeight: 700,
    padding: '5px 0 30px 0',
  },
  subtitle: {
    fontStyle: 'italic',
    fontSize: '1.4rem',
    paddingBottom: '15px',
    color: 'black',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 20px',
    },
  },
  img: {
    height: '32px',
    padding: '0px 10px',
    borderRight: '1px solid #fff',
  },
  btn: {
    color: '#fff',
    fontSize: '1.5rem',
    border: '2px solid #fff',
    borderRadius: '5px',
    margin: '10px 10px',
    width: '500px',
    fontWeight: 'normal',
    background: '#da7b1e!important',
    padding: '10px 0px',
    [theme.breakpoints.down('xs')]: {
      width: '275px',
      margin: '20px 0px',
    },
  },
  btnTypo: {
    margin: '0px 10px',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
}));
