import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  img: {
    height: '32px',
    padding: '0px 10px',
    borderRight: '1px solid #fff',
    filter: 'brightness(0)',
  },
  btn: {
    color: '#fff',
    width: '50%',
    fontSize: '1.5rem',
    border: '2px solid black',
    borderRadius: '5px',
    fontWeight: 'normal',
    background: '#f1e0c0!important',
    padding: '10px 0px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  btnTypo: {
    margin: '0px 10px',
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
}));
