import React from 'react';
import { Box, Container, Typography, Grid } from '@material-ui/core';
import { listBody, title, boldText, test } from '../../../utils/constants/careersConstants';
import { useStyles } from './style';
import Form from './Form';

function TextForm(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.grid}>
      <Grid item md={6} sm={12}>
        <Box className={classes.contentBox}>
          <Typography variant="h3" component="h3" className={classes.title}>
            {title[0]}
          </Typography>
          {listBody.map((item, index) => (
            <ul key={index}>
              <li>
                <Typography>{item}</Typography>
              </li>
            </ul>
          ))}

          <Typography variant="body1" className={classes.boldItalic}>
            {boldText}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" component="h3" className={classes.title}>
            {title[1]}
          </Typography>
          <Typography variant="body1" component="div" className={classes.justifyBody}>
            {test[1].body}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={6} sm={12} className={classes.fullWidth}>
        <Form />
      </Grid>
    </Grid>
  );
}

export default TextForm;
