import {
  Box,
  Container,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NavBarWhite from './components/nav_bar_white';
import TopBar from './components/top_bar';
import heroimage from '../../assets/header_images/heroimage.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      height: '2000px',
      width: '100%',
      backgroundColor: 'rgba(248, 147, 0, 0.3)',
      background: `url(${heroimage})`,
      backgroundBlendMode: 'multiply',
    },
  }),
);

const Header = () => {
  const classes = useStyles();
  const [isScroll, setIsScroll] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        setIsScroll(false);
      } else {
        setIsScroll(true);
      }
    };
  }, []);
  return (
    <div>
      <TopBar isScroll={isScroll} />
      <NavBarWhite isScroll={isScroll} />
    </div>
  );
};

export default Header;
