import React from 'react';
import {
  Box,
  Container,
  TextField,
  List,
  Typography,
  Grid,
  MenuItem,
  Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useStyles } from './style';
import * as yup from 'yup';
import { sizeList } from '../../utils/constants/sizeList';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.number().required('Phone is required'),
  company: yup.string().required('Company is required'),
  companySize: yup.string().required('Company size is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  comment: yup.string().required('Comment is required'),
});

function Form(props) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      company: '',
      companySize: '',
      comment: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.formRoot}>
        <TextField
          className={classes.textField}
          id="firstName"
          name="firstName"
          variant="outlined"
          placeholder="First Name *"
          size="small"
          inputProps={{
            style: { fontSize: 14 },
          }}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          fullWidth
        />
        <TextField
          className={classes.textField}
          id="lastName"
          name="lastName"
          variant="outlined"
          placeholder="Last Name *"
          size="small"
          inputProps={{
            style: { fontSize: 14 },
          }}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          fullWidth
        />
        <TextField
          className={classes.textField}
          id="phone"
          name="phone"
          variant="outlined"
          placeholder="Phone *"
          size="small"
          inputProps={{
            style: { fontSize: 14 },
          }}
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          fullWidth
        />
        <TextField
          className={classes.textField}
          id="email"
          name="email"
          variant="outlined"
          placeholder="Email *"
          size="small"
          inputProps={{
            style: { fontSize: 14 },
          }}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          fullWidth
        />
        <TextField
          className={classes.textField}
          id="company"
          name="company"
          variant="outlined"
          placeholder="Company *"
          size="small"
          inputProps={{
            style: { fontSize: 14 },
          }}
          value={formik.values.company}
          onChange={formik.handleChange}
          error={formik.touched.company && Boolean(formik.errors.company)}
          fullWidth
        />
        <TextField
          className={classes.textField}
          id="companySize"
          name="companySize"
          variant="outlined"
          inputProps={{ style: { fontSize: 14 } }}
          label={formik.values.companySize === '' ? 'Company size*' : ''}
          fullWidth
          select
          size="small"
          value={formik.values.companySize}
          onChange={formik.handleChange}
          error={formik.touched.companySize && Boolean(formik.errors.companySize)}
        >
          {sizeList.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.textField}
          id="comment"
          name="comment"
          variant="outlined"
          placeholder="Comment"
          inputProps={{ style: { fontSize: 14 } }}
          multiline
          rows={6}
          value={formik.values.comment}
          onChange={formik.handleChange}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          fullWidth
        />
        <Button variant="outlined" type="submit" className={classes.btn} fullWidth>
          SUBMIT
        </Button>
      </Box>
    </form>
  );
}

export default Form;
