export const appConstants = {
  dateFormat: 'DD/MM/YYYY',
  dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
};

export const navbarTitle = ['Who we are', 'Our team', 'How We Engage', 'Blog', 'Contact Us'];

export const subtitles = ['ACCESS TO VIETNAM ENGINEERING TALENT, WHEN AND HOW YOU NEED IT'];

export const title = [''];

export const buttonGroupTitle = ['307-212-8124', 'info@bellgardeninc.com'];

export const addressTitle = ['1910 Thomas Avenue, Cheyenne, WY 82001'];
