import agile from '../../features/who_we_are/assets/agile-management.png';
import coding from '../../features/who_we_are/assets/coding.png';
import dtb from '../../features/who_we_are/assets/database-admin.png';
import test from '../../features/who_we_are/assets/qa-testing.png';
import uxui from '../../features/who_we_are/assets/ux-ui-design.png';
import dev from '../../features/who_we_are/assets/devops.png';

export const header = ['WHO WE ARE'];

export const content = [
  ' 100% onshore for 20 years, Bella Garden Inc. has the on-demand technical resources to help you meet your business goals. Our highly vetted network of software engineering resources quickly integrate into your organization to help you address immediate and continuing initiatives.',
  'With no minimum term or commitment, you have the flexibility to scale up/down on demand. Our qualified engineers, QA specialists, project managers, UI/UX experts and full agile teams are ready to start immediately.',
];

export const capabilities = [
  {
    name: 'UI/UX Design',
    logo: uxui,
  },
  {
    name: 'Coding/Programming',
    logo: coding,
  },
  {
    name: 'Agile Project Management',
    logo: agile,
  },
  {
    name: 'Devops',
    logo: dev,
  },
  {
    name: 'Database Administration',
    logo: dtb,
  },
];

export const title = 'No Job is Too Large or Complex';

export const body =
  'Our development team is passionate about staying ahead of the technology curve and utilizing the latest tools. We are platform agnostic, meaning that we can work with virtually any technology stack, from Java to .NET and everything in between. Our comprehensive business software solutions can also scale as large as you require. No matter the platform or programming language, we can accommodate your needs. If there is something specific you are looking for here but don’t see it, please give us a call. We’re confident we can build a software solution that’s right for you.';
