import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import surgelogo from '../../../../assets/header_images/surgelogo.png';
import vatek from '../../../../assets/header_images/vatek.png';
import { useHistory, NavLink } from 'react-router-dom';
import useWindowSize from '../../../../hook/useWindowSize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    logo: {
      maxHeight: 70,
      filter: 'brightness(1000)',
      '&:hover': {
        background: 'transparent',
      },
    },
    navbar: {
      background: 'rgba(248, 147,0,0.8)',
      boxShadow: 'none',
      paddingLeft: '10.5vw',
      paddingRight: '8.2vw',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '3vw',
        paddingRight: '3vw',
      },
    },
    btnGroup: {},
    dropdownMenu: {
      display: 'none',
    },
    textButton: {
      fontSize: '4rem',
      fontWeight: 'bold',
      boxShadow: 'none',
      textTransform: 'none',
      padding: '5px 10px',
      border: 'none',
      lineHeight: 1.5,
      color: 'white',
      backgroundColor: 'transparent',
      transition: '0.3s',
      '&:hover': {
        filter: 'brightness(0.7)',
        backgroundColor: 'transparent',
        transition: '0.5s',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
    btnText: {
      fontSize: '16px',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.3rem',
      },
    },
    textButtonBlack: {
      fontSize: '2.25rem',
      fontWeight: 'bold',
      boxShadow: 'none',
      textTransform: 'none',
      padding: '5px 10px',
      border: 'none',
      lineHeight: 1.5,
      color: 'black',
      backgroundColor: 'transparent',
      transition: '0.3s',
    },
    [theme.breakpoints.down('sm')]: {
      btnGroup: {
        display: 'none',
      },
      logo: {
        filter: 'none',
      },
      navbar: {
        backgroundColor: 'white',
      },
      dropdownMenu: {
        display: 'flex',
        color: 'black',
        fontSize: '2rem',
      },
    },
    linkText: {
      textDecoration: 'none',
      color: 'white',
      padding: '5px',
      '&:hover': {
        color: 'white',
      },
    },
  }),
);

export const pages = [
  {
    name: 'Who we are',
    navigate: '/whoweare',
  },
  { name: 'How we engage', navigate: '/howweengage' },
  { name: 'Blog', navigate: '/blog' },
  {
    name: 'Contact us',
    navigate: '/contact',
  },
];

const NavBar = () => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const { width, height } = useWindowSize();

  const onNavigationHandler = (path) => {
    history.push(path);
    window.location.reload();
  };
  const toggleDrawer = (open) => (event) => {
    setOpen(open);
  };
  return (
    <div>
      <Toolbar className={classes.navbar}>
        <Box flexGrow={1}>
          {width < 768 ? (
            <Button
              className={classes.textButtonBlack}
              disableTouchRipple={true}
              onClick={() => onNavigationHandler('/')}
            >
              Bella Garden Inc.
            </Button>
          ) : (
            <Button
              className={classes.textButton}
              disableTouchRipple={true}
              onClick={() => onNavigationHandler('/')}
            >
              Bella Garden Inc.
            </Button>
          )}
        </Box>
        <Box className={classes.btnGroup}>
          {pages.map((page) => (
            <Button className={classes.textButton} disableTouchRipple={true} key={page.name}>
              <NavLink
                className={classes.linkText}
                activeStyle={{
                  background: '#fff',
                  color: '#f8b200',
                  borderRadius: '20px',
                }}
                to={page.navigate}
              >
                <Typography variant="h5" component="h1" className={classes.btnText}>
                  {page.name}
                </Typography>
              </NavLink>
            </Button>
          ))}
        </Box>
        <Box className={classes.dropdownMenu}>
          <IconButton size="medium" onClick={toggleDrawer(true)}>
            <MenuIcon style={{ fontSize: 35 }} />
          </IconButton>
          <Drawer anchor={'top'} open={open} onClose={toggleDrawer(false)}>
            <Box onClick={toggleDrawer(false)}>
              <List>
                {pages.map((page) => (
                  <ListItem onClick={() => onNavigationHandler(page.navigate)} key={page.name}>
                    <ListItemText primary={page.name} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </Box>
      </Toolbar>
    </div>
  );
};

export default NavBar;
