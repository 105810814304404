import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { signupNewsletterStyles } from './style';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

export default function SignupNewsletter() {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
  });
  const classes = signupNewsletterStyles();
  return (
    <Grid className={classes.containerBox} container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography style={{ color: '#f8b200' }} variant="h4" component="h1">
          Sign Up For Our Newsletter
        </Typography>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box className={classes.fieldBox}>
            <Box className={classes.textBoxHandle}>
              <TextField
                className={classes.textField}
                id="firstName"
                name="firstName"
                variant="outlined"
                placeholder="First Name*"
                size="small"
                inputProps={{
                  style: { fontSize: 14 },
                }}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box className={classes.fieldBox}>
            <Box className={classes.textBoxHandle}>
              <TextField
                className={classes.textField}
                id="lastName"
                name="lastName"
                variant="outlined"
                placeholder="Last Name*"
                size="small"
                inputProps={{
                  style: { fontSize: 14 },
                }}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box className={classes.fieldBox}>
            <Box className={classes.textBoxHandle}>
              <TextField
                className={classes.textField}
                id="email"
                name="email"
                variant="outlined"
                placeholder="Email Address*"
                size="small"
                inputProps={{ style: { fontSize: 14 } }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                fullWidth
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button className={classes.submitButton} type="submit">
            SUBSCRIBE
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}
