import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: '40px 24px',
  },
  background: {
    height: '100%',
    padding: '0px',
    position: 'absolute',
    zIndex: -1,
    left: '0',
    backgroundImage: `url(${require('../../assets/background/coders.png').default})`,
    backgroundRepeat: 'no-repeat',
  },
  zindex: {
    zIndex: 1,
  },
  title1: {
    fontSize: '3rem',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  title2: {
    fontSize: '2rem',
    padding: '15px 0px',
    fontWeight: 500,
  },
  body: {
    textAlign: 'justify',
  },
  formRoot: {
    background: '#F8F8FA',
    padding: '60px 20px 30px',
    border: '2px solid #F98D23',
    borderRadius: '30px',
  },
  textField: {
    background: '#F8F8FA',
    margin: '10px 0px',
    borderRadius: '0px',
  },
  btn: {
    padding: '10px 20px',
    fontSize: '1.4rem',
    background: '#F98D23',
    color: '#F8F8FA',
    '&:hover': {
      background: '#F98D23',
      filter: 'brightness(0.9)',
      transition: '0.5',
    },
  },
  center: {
    justifyContent: 'center',
  },
  listContainer: {
    margin: '0 auto',
    maxWidth: '3000px',
    transform: 'translate3d(0, 0, 0)',
    overflow: 'hidden',
  },
  logoList: {
    padding: '0px',
    top: 0,
    left: '-50%',
    transform: 'translate3d(0, 0, 0)',
    width: '500%',
    display: 'inline-block',
    [theme.breakpoints.down('md')]: {
      width: '700%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '900%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mover: {
    animation: '$moveSlideshow 300s linear infinite',
    display: 'inline-block',
    filter: 'brightness(0.2)',
  },
  '@keyframes moveSlideshow': {
    '100%': {
      transform: 'translateX(-800%)',
    },
  },
}));
