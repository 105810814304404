export const filterBoxList = [
  {
    image: require('../../assets/blogs/filter/surge-news.svg').default,
    name: 'Case Studies & Profiles',
    detail:
      'Meet some of our clients and see how we helped them achieve their business objectives.',
    tag: '',
  },
  {
    image: require('../../assets/blogs/filter/techresourcesandperspectives.svg').default,
    name: 'Technical Resources + Perspectives',
    detail: 'Learn about all things tech from some of the best in the business.',
    tag: '',
  },
  {
    image: require('../../assets/blogs/filter/company-news.svg').default,
    name: 'Company News',
    detail: 'News and details about what’s happening at Surge.',
    tag: '',
  },
];
export const tagList = [
  'API',
  'Blockchain',
  'Blog',
  'Case Studies',
  'Case Studies + Profiles',
  'Code Review',
  'Code Tips',
  'Company News',
  'Design',
  'Development',
  'Digital Transformation',
  'General',
  'JS Frameworks',
  'News',
  'Press Releases',
  'Project Management',
  'python',
  'Remote Work',
  'Retraining',
  'SCRUM',
  'Staff Augmentation',
  'Technical Resources + Perspectives',
  'Testing',
  'User Experience (UX)',
  'Workforce Development',
];
