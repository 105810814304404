import {
  AppBar,
  Box,
  IconButton,
  styled,
  Toolbar,
  Typography,
  useScrollTrigger,
  Slide,
  Theme,
  Menu,
  Button,
} from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import React, { useEffect, useState } from 'react';
import RssFeed from '@material-ui/icons/RssFeed';
import { motion } from 'framer-motion';
import NavBar from '../nav_bar';
import SearchBar from '../Search';
import SignupNewsletter from 'components/signup_newsletter';
import { Link } from 'react-router-dom';
interface IProps {
  window?: () => Window;
  children: React.ReactElement;
}

const CustomTopBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));
const LinkText = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',
  '&:hover': {
    color: '#036bfc',
  },
}));
const TypoNewsletter = styled(Typography)(({ theme }) => ({
  color: 'white',
  '&:hover': {
    color: '#036bfc',
  },
}));
const ResponsiveTopBar = styled(AppBar)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

const CustomIconButton = styled(IconButton)({
  boxShadow: 'none',
  textTransform: 'none',
  padding: '5px',
  border: 'none',
  lineHeight: 1.5,
  backgroundColor: 'transparent',
  color: 'lightgrey',
  transition: '0.3s',
  '&:hover': {
    color: '#036bfc',
  },
});

const TopToolBar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: '#393939',
  paddingLeft: '10vw',
  paddingRight: '7vw',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '2vw',
    paddingRight: '2vw',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const HideOnScroll = (props: any) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: props.threshold,
    target: props.window ? window() : undefined,
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
const TopBar = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <CustomTopBar position="fixed">
        <HideOnScroll>
          <TopToolBar variant="dense">
            <Box flexGrow={1}>
              <Typography variant="h6">
                Contact Us <LinkText to="/#">307-212-8124</LinkText>
              </Typography>
            </Box>
            <Box>
              <Toolbar variant="dense">
                <SearchBar />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Box sx={{ width: '340px', padding: '20px' }}>
                    <SignupNewsletter />
                  </Box>
                </Menu>
              </Toolbar>
            </Box>
          </TopToolBar>
        </HideOnScroll>
        <motion.div
          animate={{ opacity: props.isScroll === false ? 1 : 0 }}
          transition={{ duration: 0.1 }}
        >
          <NavBar />
        </motion.div>
      </CustomTopBar>
      <ResponsiveTopBar>
        <NavBar />
      </ResponsiveTopBar>
    </div>
  );
};

export default TopBar;
