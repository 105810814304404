/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { title, body } from '../../utils/constants/capabilitiesConstant';
import CoreTech from '../../components/CoreTechnicalCompetencies/CoreTech';
import { LogoGenerate } from '../../components/logo_genarate';
import { useStyles } from './style';

function index(props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={9}>
        <Grid item md={8} xs={12}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1">{body}</Typography>
          <Box>
            <CoreTech />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box className={classes.logoBox}>
            <Typography variant="h3" component="h3" className={classes.typo}>
              TRUSTED BY
            </Typography>
            <LogoGenerate />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default index;
