import { makeStyles } from '@material-ui/core';
import { ITheme } from 'theme';

export const signupNewsletterStyles = makeStyles((theme: ITheme) => ({
  containerBox: {
    margin: 'auto',
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
    padding: '10px 0px',
    backgroundColor: '#F8F8FA',
    textAlign: 'center',
  },
  textField: {
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
        fill: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#3ba8ff',
      },
    },
  },
  submitButton: {
    width: '90%',
    padding: '15px',
    margin: '5px',
    color: '#fff',
    fontSize: '15px',
    border: '2px solid white',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#ff9700',
    '&:hover': {
      backgroundColor: '#ec8c00',
    },
    '&:active': {
      backgroundColor: '#ec8c00',
    },
  },
  fieldBox: {
    margin: '15px 0px',
  },
  textBoxHandle: {
    backgroundColor: 'white',
  },
}));
