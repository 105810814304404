import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';
import { useStyles } from './style';
import { Link, Redirect } from 'react-router-dom';

const OrangeButton = ({ src, name, onClick }) => {
  const classes = useStyles();
  return (
    <Button className={classes.btn} onClick={onClick}>
      <img src={src} alt="" className={classes.img} />
      <Typography variant="body1" component="h1" className={classes.btnTypo}>
        {name}
      </Typography>
    </Button>
  );
};

export default OrangeButton;
