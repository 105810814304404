import { makeStyles, createTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: '#393939',
    height: 'auto',
    paddingTop: '18px',
    paddingBottom: '18px',
    fontSize: '1rem',
    color: '#888',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  linkButton: {
    fontSize: '1rem',
    border: '0px solid',
    color: '#888',
    '&:hover': {
      background: 'transparent',
      color: '#428bff',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'block',
    },
  },
}));
