import React from 'react';
import { Box, Typography, Container, Grid } from '@material-ui/core';
import { header, content } from '../../../utils/constants/capabilitiesConstant';
import Video from '../../../components/VideoPlayer/VideoPlayer';
import { useStyles } from './style';

function WhoWeAre() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item lg={7} md={12}>
          <Box className={classes.typoBox}>
            <Typography variant="h1" className={classes.headerText} component="h1">
              {header}
            </Typography>
            {content.map((item, index) => (
              <Typography key={index} variant="body1" className={classes.bodyText} component="h1">
                {item}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WhoWeAre;
