import React, { useEffect } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import surgelogo from '../../../../assets/header_images/surgelogo.png';
import vatek from '../../../../assets/header_images/vatek.png';
import { motion } from 'framer-motion';
import { pages } from '../nav_bar';
import { useHistory, NavLink } from 'react-router-dom';

interface IProps {
  isScroll: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    whiteAppBar: {
      backgroundColor: 'transparent',
    },
    logoBox: {
      marginLeft: '9vw',
    },
    logo: {
      maxHeight: 80,
      paddingTop: '5px',
    },
    navbar: {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    btnGroup: {
      marginRight: '7vw',
      [theme.breakpoints.down('md')]: {
        margin: '0',
      },
    },
    textButton: {
      fontSize: '2rem',
      boxShadow: 'none',
      textTransform: 'none',
      padding: '5px 10px',
      border: 'none',
      lineHeight: 1.5,
      color: 'black',
      backgroundColor: 'transparent',
      transition: '0.5s',
      '&:hover': {
        color: 'lightgrey',
        backgroundColor: 'transparent',
      },
    },
    [theme.breakpoints.down('sm')]: {
      whiteAppBar: {
        display: 'none',
      },
    },
    linkText: {
      textDecoration: 'none',
      color: 'black',
    },
    [theme.breakpoints.down('md')]: {
      logoBox: {
        marginLeft: '0',
      },
    },
  }),
);

const NavBarWhite = ({ isScroll }: IProps) => {
  const history = useHistory();
  const classes = useStyles();
  const onNavigationHandler = (path) => {
    history.push(path);
    window.location.reload();
  };

  useEffect(() => {
    console.log('rendered');
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div animate={{ opacity: isScroll ? 1 : 0 }} transition={{ duration: 0.2 }}>
      <AppBar className={classes.whiteAppBar}>
        <Toolbar className={classes.navbar}>
          <Box className={classes.logoBox} flexGrow={1} display={{ xs: 'none', sm: 'block' }}>
            <Button
              className={classes.textButton}
              disableTouchRipple={true}
              onClick={() => onNavigationHandler('/')}
            >
              Bella Garden Inc.
            </Button>
          </Box>
          <Box className={classes.btnGroup}>
            {pages.map((page, index) => (
              <Button className={classes.textButton} disableTouchRipple={true} key={page.name}>
                <NavLink
                  key={index}
                  className={classes.linkText}
                  to={page.navigate}
                  activeStyle={{
                    color: '#f8b200',
                  }}
                >
                  <Typography
                    style={{ fontSize: '16px', fontWeight: 'bolder' }}
                    variant="h5"
                    component="h1"
                  >
                    {page.name}
                  </Typography>
                </NavLink>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </motion.div>
  );
};

export default NavBarWhite;
