import logo1 from '../../assets/HowWeEngageImg/demand-based.png';
import logo2 from '../../assets/HowWeEngageImg/Staffing-Needs.png';
import logo3 from '../../assets/HowWeEngageImg/200-hour.png';
import logo4 from '../../assets/HowWeEngageImg/Ramp-Up-or-Down.png';

export const title = ['HOW WE ENGAGE'];

export const content = [
  {
    title: '1 . Tell Us Your Needs',
    content:
      'From a single web developer to multiple agile teams; designers and analysts to PMs and QA engineers; senior to junior level. We have the depth and breadth you need to get the job done.',
    logo: logo1,
  },
  {
    title: '2. We Find The Best Fit',
    content: 'BellaGarden selects the best available resources to fit your project requirements.',
    logo: logo2,
  },
  {
    title: '3. Get To Work Fast',
    content:
      'BellaGarden is ready to start usually within one week, quickly integrating with your team.',
    logo: logo3,
  },
  {
    title: '4. Grow To Meet Your Demand',
    content:
      'With no minimum term or commitment, you have the flexibility to scale up/down quickly and conveniently.',
    logo: logo4,
  },
];
