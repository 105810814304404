import { styled, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '70px 14%',
    [theme.breakpoints.down('lg')]: {
      padding: '70px 24px',
    },
    textAlign: 'center',
  },
  typoBox: {
    textAlign: 'justify',
    [theme.breakpoints.down('lg')]: {
      paddingRight: '0px',
    },
  },
  headerText: {
    fontSize: '3rem',
    color: '#505050',
    fontWeight: 'bold',
  },
  bodyText: {
    padding: '10px 0px',
  },
  logoItemBar: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoItem: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#fff',
    alignItems: 'center',
    marginRight: '50px',
    [theme.breakpoints.down('md')]: {
      marginRight: '20px',
    },
  },
  btnCapabilities: {
    color: '#fff',
    '&:hover': {
      color: 'black',
      background: 'transparent',
    },
    padding: '0px',
    fontSize: '13px',
    fontWeight: 500,
  },
  bar: {
    background: '#f8b200',
    padding: '20px 14%',
    marginBottom: '35px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '20px 24px',
    },
    margin: '0',
  },
  img: {
    filter: 'brightness(10)',
    marginRight: '5px',
  },
  typoText: {
    fontSize: '1.2rem',
  },
}));
