import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '80px 4%',
  },
  videoBox: {
    textAlign: 'center',
    background: '#505050',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${require('../../assets/background/backgroundFooter.svg').default})`,
    padding: '50px 30%',
  },
  typoText: {
    color: '#fff',
    fontWeight: 'bold',
    paddingTop: '10px',
  },
}));
