import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { dataTechnicals } from '../../../utils/constants/coreTechnicalConstants';
import { useStyles } from './style';
import { Link, useHistory } from 'react-router-dom';

function Data(props) {
  const classes = useStyles();
  const history = useHistory();

  const onNavigationHandler = (path) => {
    history.push(path);
  };

  return (
    <Container className={classes.container} maxWidth={false}>
      {dataTechnicals.map((item, index) => (
        <Link key={index} className={classes.linkText} to={`/developers/${item.name}`}>
          <Box key={index} className={classes.box}>
            <img src={item.img} alt="" className={classes.img}></img>
            <Typography>{item.name}</Typography>
          </Box>
        </Link>
      ))}
    </Container>
  );
}

export default Data;
