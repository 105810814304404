import React from 'react';
import { Box, Container, Button, Typography, Grid } from '@material-ui/core';
import { useStyles } from './style';
import OrangeButton from 'components/Button/OrangeButton';
import { buttonTitle } from '../../../../utils/constants/buttonConstants';
import { title, subtitles } from '../../../../utils/constants/appConstants';
import { useHistory } from 'react-router-dom';

function SurgeButton() {
  const classes = useStyles();
  const history = useHistory();

  const onNavigationHandler = (path) => {
    history.push(path);
  };

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle1" align="center" className={classes.subtitle} component="h1">
        {subtitles}
      </Typography>
      <Grid container spacing={5}>
        {buttonTitle.map((item, index) => (
          <Grid item md={12} xs={12} key={index}>
            <OrangeButton
              src={item.logo}
              name={item.name}
              onClick={() => onNavigationHandler(item.navigate)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default SurgeButton;
