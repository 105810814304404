import {
  Box,
  Container,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { LogoGenerate } from '../../components/logo_genarate/index';
import { sizeList } from '../../utils/constants/sizeList';
import { CustomMediaCard } from '../../components/Media_Card/index';
import { employeesList } from '../../utils/constants/employeesList';
import { contactStyles } from './style';
import { theme } from 'theme';
import { Link } from '@material-ui/core';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phone: yup.number().required('Phone is required'),
  companySize: yup.string().required('Company size is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  comment: yup.string().required('Comment is required'),
});

const Contact = ({ body, title }) => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      company: '',
      companySize: '',
      email: '',
      comment: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
  });
  const classes = contactStyles();
  return (
    <Container maxWidth="xl">
      <Box className={classes.containerBox}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.center}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box className={classes.typographyBox}>
                <Typography
                  className={classes.titleText}
                  style={{ color: '#f8b200' }}
                  variant="h2"
                  component="h3"
                >
                  {title}
                </Typography>
                <Typography className={classes.bodyText} variant="h3" component="p">
                  {body}
                </Typography>
              </Box>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Link
                  target="_blank"
                  href="https://docs.google.com/forms/d/1k4HaRM5JOAjjyF2UT1ph-3XbJvHCjHchAdoX4_w2Lso/"
                  underline="none"
                >
                  <Button className={classes.submitButton} type="submit">
                    SEND MESSAGE
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box className={classes.sponsorBox}>
              <Box className={classes.typographyBox}>
                <Typography variant="h3" component="h3">
                  Trusted by:
                </Typography>
              </Box>
              <LogoGenerate />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default Contact;
