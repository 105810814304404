import { makeStyles } from '@material-ui/core';
import { ITheme, theme } from 'theme';

export const filterCarđStyle = makeStyles((theme: ITheme) => ({
  containerBox: {
    width: '90%',
    backgroundColor: '#fff',
    color: '#212529',
    padding: '4%',
    boxShadow: '0 3px 15px 3px rgb(0 0 0 / 20%)',
  },
  borderBox: {
    height: '240px',
    border: 'solid 2px #f8b200',
    padding: '30px 14px 10px 14px',
    textAlign: 'center',
  },
  imgBox: {
    padding: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  image: {
    margin: 'auto',
    alignContent: 'center',
    verticalAlign: 'middle',
    width: '90px',
    height: '90px',
    [theme.breakpoints.down('lg')]: {
      width: '80px',
      height: '80px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60px',
      height: '60px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90px',
      height: '90px',
    },
  },
  linkText: {
    textDecoration: 'none',
  },
  typoName: {
    width: 'fit-content',
    borderBottom: 'solid 2px #f8b200',
    margin: 'auto',
    marginBottom: '20px',
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'uppercase',
    lineHeight: '24px',
  },
  typoDetail: {
    color: '#a0a9b0',
  },
}));
