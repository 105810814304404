import React from 'react';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import { TabList, TabPanel, TabContext } from '@material-ui/lab';
import { tabPanels } from 'utils/constants/coreTechnicalConstants';
import WebCloud from './Components/WebCloud';
import Data from './Components/Data';
import Mobile from './Components/Mobile';
import Desktop from './Components/Desktop';
import Devops from './Components/Devops';
import QA from './Components/QA';
import { useStyles } from '../../features/CoreTech/style';
import { styled } from '@material-ui/core';

const components = [<WebCloud />, <Data />, <Mobile />, <Desktop />, <Devops />];

const StyleTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: 700,
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  '& .MuiTab-root': {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  '& .PrivateTabIndicator-colorPrimary-166': {
    background: '#f8b200',
  },
  '& .MuiTab-textColorPrimary.Mui-selected': {
    color: '#f8b200',
  },
}));

function CoreTech(props) {
  const [value, setValue] = React.useState('1');
  const classes = useStyles();

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box>
        <TabContext value={value}>
          <Box>
            <StyleTabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant={'scrollable'}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="on"
            >
              {tabPanels.map((tab, index) => (
                <Tab
                  label={tab}
                  value={`${index + 1}`}
                  key={index}
                  disableFocusRipple={true}
                  disableRipple={true}
                  className={classes.tab}
                />
              ))}
            </StyleTabList>
          </Box>
          {components.map((component, index) => (
            <TabPanel key={index} value={`${index + 1}`}>
              {component}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </div>
  );
}

export default CoreTech;
