import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${require('../../assets/background/backgroundFooter.svg').default})`,
    background: '#393939',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'white',
    margin: '0',
    padding: '70px 14%',
    [theme.breakpoints.down('lg')]: {
      padding: '70px 24px',
      textAlign: 'justify',
    },
  },
  titleBox: {
    padding: '0px 0px 30px 0px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'justify',
    },
  },
  gridItem: {
    display: 'flex',
  },
  textBox: {
    display: 'block',
    lineHeight: '15px',
  },
  img: {
    height: '48px',
    width: '48px',
    padding: '0px 25px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
