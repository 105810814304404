import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {},
  img: {
    height: '60px',
    paddingBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      height: '40px',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '0px 10%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
    },
  },
  box: {
    textAlign: 'center',
    padding: '40px 60px',
    [theme.breakpoints.down('lg')]: {
      padding: '30px 40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 15px',
    },
  },
  linkText: {
    color: '#757575',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
